@use 'sass:math';
@import 'src/styles/shared.scss';

.cofi-menu {
  background: #ffffff;
  box-shadow: $shadow-lg;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;

  @include tablet {
    position: relative;
    padding: $space-sm + 6px 0 0;

    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;

    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: $space-sm $space-sm * 2;
    }

    &:hover {
      cursor: pointer;
      background: $light-color;
    }

    svg {
      margin-right: $space-sm;
    }
  }

  &-lang {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 0 50%;

    @include tablet {
      flex: auto;
      flex-direction: column;
    }
  }

  .divider {
    margin: math.div($space-sm, 2) #{$space-sm * 2};
    background: $gray-color;
    height: 1px;
    width: auto;
  }
}

.cofi-menu-button {
  background: #ffffff;
  color: #000000;

  box-shadow: $shadow-sm;

  border: none;
  border-radius: 100%;
  width: 44px;
  height: 44px;
  cursor: pointer;
  line-height: 0;

  &:hover {
    background: $text-gradient-color;

    svg {
      path {
        fill: black;
      }
      .backContent {
        color: black;
      }
    }
  }
}
