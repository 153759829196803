@import './shared.scss';
@import './popup.scss';
@import './toast.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: OPPOSans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
@font-face {
  font-family: AmericanTypewriterBold;
  src: url('./AmericanTypewriterBold.woff');
}
html,
body,
#root {
  width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.link {
  color: $link-color;
  padding-bottom: 4px;
  border-bottom: 1px solid $link-color;
}

.cursor-pointer {
  cursor: pointer;
}

.container {
  @include tablet {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
}

.line-height-none {
  list-style: none;
}

.cofi-page {
  padding-bottom: $space + 4 * $space-md;
  @include tablet {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-bottom: $space * 2;
  }

  & > section {
    margin-top: $space-sm;

    @include tablet {
      margin-top: $space;
    }
  }

  & > section + section {
    margin-top: $space-sm * 2;

    @include tablet {
      margin-top: $space * 2;
    }
  }
}

.display-block {
  display: block;
}

.animation-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.w100 {
  width: 100%;
}
