@import 'src/styles/shared.scss';

.cofi-nav {
  width: 100%;
  max-width: $content-width;

  &-logo {
    display: block;
    @include tablet {
      display: none;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: $shadow-sm;
    border-radius: $border-radius;
    width: 100%;
    padding: $space-sm $space;

    @include tablet {
      padding: $space-sm $space * 4;
    }
  }

  li {
    &:active {
      background: $text-gradient-color;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    &.active {
      background: $text-gradient-color;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
}
