@import 'src/styles/shared.scss';

.cofi-tag {
  background: #ffffff;
  box-shadow: $shadow-sm;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  padding: 1px $space-sm;

  background: $text-gradient-color;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  &.primary {
    color: white;
    background-clip: unset;
    -webkit-background-clip: unset;
  }
}
