@import 'src/styles/shared.scss';

.cofi-footer {
  display: block;
  @include tablet {
    display: none;
  }

  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;

  background: #ffffff;
  box-shadow: $shadow-sm;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: $space-sm;

  & > * + * {
    margin-left: $space-sm;
  }
}
